import _extends from "/ecomm-marketplace/node_modules/next/node_modules/@babel/runtime/helpers/esm/extends";
var __jsx = React.createElement;
import React from 'react';

var ChevronIcon = function ChevronIcon(props) {
  return __jsx("svg", _extends({
    width: "12",
    height: "8",
    fill: "#677882"
  }, props), __jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M10.4549 0.280663C10.8087 -0.0935387 11.3811 -0.0935387 11.7349 0.280663C12.0839 0.644902 12.0891 1.2408 11.7458 1.61168C11.7422 1.61528 11.7385 1.61916 11.7349 1.62303L6.63861 6.9233C6.28487 7.29225 5.7179 7.29225 5.3639 6.9233L0.265046 1.62303C-0.0840017 1.25879 -0.0889545 0.662892 0.254097 0.292288C0.257747 0.288137 0.261396 0.284538 0.265046 0.280663C0.618524 -0.0896638 1.18654 -0.0896638 1.53976 0.280663L5.99995 4.62883L10.4549 0.280663Z"
  }));
};

export default ChevronIcon;