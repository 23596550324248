import React from 'react';

type SvgBlackSparkleProps = {
  width?: number;
  height?: number;
};

export function BlackSparkleIcon({ width = 16, height = 16 }: SvgBlackSparkleProps): JSX.Element {
  return (
    <svg width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <title>Black Sparkle Icon</title>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.40487 3.61035C6.63241 3.61035 6.83126 3.76398 6.88868 3.98416C7.26034 5.40904 7.68016 6.33472 8.33737 7.03299C8.99623 7.733 9.9414 8.25511 11.4694 8.77C11.6728 8.83853 11.8097 9.0292 11.8097 9.24382C11.8097 9.45843 11.6728 9.64911 11.4694 9.71764C9.94141 10.2326 8.99623 10.7547 8.33738 11.4547C7.68016 12.153 7.26034 13.0787 6.88868 14.5035C6.83125 14.7237 6.63241 14.8773 6.40487 14.8773C6.17733 14.8773 5.97849 14.7237 5.92106 14.5035C5.5494 13.0786 5.12959 12.153 4.47237 11.4547C3.81352 10.7547 2.86835 10.2326 1.34033 9.71764C1.13695 9.64911 0.999998 9.45843 1 9.24382C1 9.0292 1.13696 8.83853 1.34034 8.77C2.86835 8.25511 3.81353 7.733 4.47237 7.03299C5.12958 6.33472 5.5494 5.40904 5.92106 3.98416C5.97849 3.76398 6.17733 3.61035 6.40487 3.61035Z'
        fill='#2C3236'
      />
      <path
        d='M13.3584 10.303C13.5848 10.303 13.7829 10.4551 13.8414 10.6738C13.9912 11.2337 14.1496 11.5536 14.373 11.7851C14.5993 12.0195 14.9411 12.2137 15.5624 12.4179C15.7676 12.4853 15.9063 12.6769 15.9063 12.8929C15.9063 13.1089 15.7676 13.3005 15.5624 13.3679C14.9411 13.5721 14.5993 13.7663 14.373 14.0007C14.1496 14.2322 13.9912 14.5522 13.8414 15.1121C13.7829 15.3308 13.5848 15.4829 13.3584 15.4829C13.132 15.4828 12.9339 15.3307 12.8754 15.1121C12.7256 14.5521 12.5673 14.2322 12.3438 14.0007C12.1176 13.7663 11.7758 13.5721 11.1545 13.3679C10.9493 13.3005 10.8106 13.1089 10.8105 12.8929C10.8105 12.6769 10.9492 12.4853 11.1545 12.4179C11.7758 12.2137 12.1176 12.0195 12.3439 11.7851C12.5673 11.5536 12.7256 11.2337 12.8754 10.6738C12.9339 10.4551 13.132 10.303 13.3584 10.303Z'
        fill='#2C3236'
      />
      <path
        d='M12.4093 1C12.6366 0.999996 12.8353 1.15338 12.893 1.37332C13.0989 2.15973 13.3237 2.63806 13.6574 2.99116C13.9929 3.34619 14.4863 3.62529 15.3351 3.91015C15.5388 3.97851 15.676 4.16933 15.676 4.38417C15.676 4.599 15.5388 4.78983 15.3351 4.85818C14.4863 5.14305 13.9929 5.42215 13.6574 5.77717C13.3237 6.13027 13.0989 6.6086 12.893 7.39499C12.8353 7.61493 12.6366 7.76832 12.4093 7.76831C12.1819 7.76831 11.9832 7.61492 11.9256 7.39498C11.7196 6.60859 11.4948 6.13027 11.1612 5.77717C10.8257 5.42215 10.3322 5.14305 9.48348 4.85818C9.27981 4.78982 9.14258 4.599 9.14258 4.38417C9.14258 4.16933 9.27981 3.97851 9.48348 3.91016C10.3322 3.62529 10.8257 3.34619 11.1612 2.99116C11.4948 2.63807 11.7196 2.15974 11.9256 1.37334C11.9832 1.1534 12.1819 1 12.4093 1Z'
        fill='#2C3236'
      />
    </svg>
  );
}
