import React from 'react';
import styled from 'styled-components';

import Header from './header';

export default function SidebarSection(props) {
  const { addPaddingRight = false, children, collapsible = true, heading, startsOpen = true } = props;

  const [isOpen, setIsOpen] = React.useState(startsOpen || !collapsible);

  function handleOpen() {
    if (!collapsible) {
      return;
    }
    setIsOpen(!isOpen);
  }

  return (
    <Container addPaddingRight={addPaddingRight}>
      <Header collapsible={collapsible} heading={heading} isOpen={isOpen} onOpen={handleOpen} />
      {isOpen && children}
    </Container>
  );
}

const Container = styled.div`
  width: 192px;
  padding: ${({ addPaddingRight }) => (addPaddingRight ? `30px 37px 30px 0` : `30px 0`)};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.blueGrey[90]}`};

  &:last-of-type {
    border-bottom: none;
  }

  &:first-of-type {
    padding-top: 5px;
  }
`;
