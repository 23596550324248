import styled, { css } from 'styled-components';
import React from 'react';
import { useAmplitude } from 'shared/hooks/use-amplitude';
import useDispensary from 'src/dispensary/hooks/use-dispensary';

import { Link, Button } from 'components/core';

import { useDispensaryCategory } from 'src/hooks/use-dispensary-category';
import usePaths from 'hooks/use-paths';
import useProductQueryParams from 'hooks/use-product-query-params';

export default function SubcategoryPill({ isSpecials, isSubcategory, label, value, selected }) {
  const category = useDispensaryCategory();
  const { href, route } = usePaths({ category, specials: isSpecials, subcategory: isSubcategory ? value : null });
  const { defaults, generateQueryParams } = useProductQueryParams();
  const amplitude = useAmplitude();
  const { dispensary } = useDispensary();

  const removeSubcategoryQueryParams = selected || !isSubcategory || !isSpecials;

  const newQuery = {
    ...generateQueryParams(`subcategories`, removeSubcategoryQueryParams ? defaults.subcategories : [value]),
  };
  delete newQuery.page;

  const trackEvent = () => {
    amplitude.log(`Subcategory Filter`, {
      description: `The user applies the subcategory filter option within a category page`,
      subcategorySelected: label,
      dispensaryId: dispensary?.id,
      dispensaryName: dispensary?.name,
    });
  };

  return (
    <Container>
      <Link href={href} route={route} query={newQuery} scroll={false}>
        <Pill selected={selected} onClick={trackEvent}>
          {label}
        </Pill>
      </Link>
    </Container>
  );
}

const Container = styled.div`
  margin-right: 8px;
`;

const selectedStyles = css`
  background-color: ${({ theme }) => theme.colors.grey[80]};
  color: ${({ theme }) => theme.colors.primaryBlack};
`;

const Pill = styled(Button)`
  border-radius: 9999px;
  text-transform: capitalize;
  white-space: nowrap;
  font-size: 12px;
  height: 35px;
  padding: 11px 15px;
  color: ${({ theme }) => theme.colors.grey[30]};
  background-color: ${({ theme }) => theme.colors.blueGrey[95]};
  ${({ selected }) => selected && selectedStyles}
`;
