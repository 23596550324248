import React from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';

import ContentWrapper from 'components/core/content-wrapper';

export function TwoColumn({ children, ...props }) {
  return (
    <ContentWrapper {...props}>
      <Container className='two-column-content'>{children}</Container>
    </ContentWrapper>
  );
}

const Container = styled.div`
  display: flex;
  padding: 60px 0;
  ${space}
`;

export const TwoColumnSidebar = styled.aside`
  border-right: 1px solid ${({ theme }) => theme.colors.blueGrey[90]};
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
  height: max-content;
  margin-right: 39px;
  width: 191px;
`;

export const TwoColumnContent = styled.div`
  flex: 1 0 0%;
  min-width: 0;
`;
