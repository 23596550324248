import React from 'react';
import styled, { useTheme } from 'styled-components';
import Link from 'next/link';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useTranslation from 'hooks/use-translation';
import useProductQueryParams from 'hooks/use-product-query-params';
import useUI from 'hooks/use-ui';

import SubcategoriesPills from 'components/subcategories-pills';
import ChevronIcon from 'shared/components/chevron-icon';
import { MobileOnly, DesktopOnly, Clickable } from 'shared/components';
import IndividualBogoCard from 'shared/components/specials/individual-bogo-card';
import { Typography } from 'src/typography';
import { MenuSortV2 } from 'src/components/menu-sort-v2';

export default function ContentHeader(props) {
  const {
    children,
    heading,
    className,
    searchString,
    count,
    dispensary,
    isMobileEcommApp,
    selectedSpecial,
    offerSpecialPage,
    saleSpecialPage,
    showExpiredSpecial = false,
    description,
  } = props;

  const flags = useFlags();

  const showSearchHeader = !!searchString;
  const specialPageVariant = offerSpecialPage || saleSpecialPage;

  let menuHeaderTitle = (
    <Text>
      <Typography.Heading size='medium' tag='h1' casing='capitalize'>
        {heading}
      </Typography.Heading>
    </Text>
  );

  if (showSearchHeader) {
    menuHeaderTitle = <SearchResultsHeader searchString={searchString} count={count} />;
  }

  const shouldShowSpecialCard = specialPageVariant && selectedSpecial;
  const updatedFilterIconsEnabled = flags['growth.ecomm.filter-icon-update.rollout'] ?? false;

  return (
    <OuterContainer hideBorder={showExpiredSpecial}>
      <HeadingContainer className={className} data-testid='heading-container'>
        {!isMobileEcommApp && specialPageVariant && <AllSpecialsBreadcrumb dispensary={dispensary} />}
        {shouldShowSpecialCard && (
          <OfferCardContainer>
            <IndividualBogoCard
              displayImage={selectedSpecial.menuDisplayImage}
              displayName={selectedSpecial.menuDisplayName || selectedSpecial.name}
              displayDescription={selectedSpecial.menuDisplayDescription || selectedSpecial.description}
              showExpiredSpecial={showExpiredSpecial}
            />
          </OfferCardContainer>
        )}
        <MobileOnly>
          {specialPageVariant && (
            <PillContainer>
              <SubcategoriesPills
                isSpecials
                specialId={selectedSpecial?._id}
                specialType={selectedSpecial?.specialType}
              />
            </PillContainer>
          )}
        </MobileOnly>
        {!showExpiredSpecial && (
          <MenuHeaderWithFilters>
            <DesktopOnly>{!isMobileEcommApp && menuHeaderTitle}</DesktopOnly>
            <MobileOnly>
              {!updatedFilterIconsEnabled && menuHeaderTitle}
              {updatedFilterIconsEnabled && <MenuSortV2 />}
            </MobileOnly>
            {children && <Filters>{children}</Filters>}
            {description && (
              <DescriptionContainer>
                <Description size='large'>{description}</Description>
              </DescriptionContainer>
            )}
          </MenuHeaderWithFilters>
        )}
      </HeadingContainer>
    </OuterContainer>
  );
}

const MenuHeaderWithFilters = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

const DescriptionContainer = styled.div`
  margin-bottom: 8px;
  width: 100%;
`;

const Description = styled(Typography.Body)`
  color: #4b5257;
  max-width: 658px;
`;

const AllSpecialsBreadcrumb = ({ dispensary }) => {
  const { customized } = useTheme();
  const UI = useUI();
  const { t } = useTranslation();
  return (
    <BreadcrumbContainer>
      <Link href={`/${UI.dispensaryRootUrl}/${dispensary.cName}/specials`}>
        <StyledSpan>
          <BackIcon fill={customized.colors.buttonsLinks} />
          {t('common.all_specials', 'All Specials')}
        </StyledSpan>
      </Link>
    </BreadcrumbContainer>
  );
};

const SearchResultsHeader = ({ count, searchString }) => {
  const { t } = useTranslation();
  const { resetQueryParams } = useProductQueryParams();

  return (
    <SearchResultsContainer>
      {t('common.showing', {
        defaultValue: 'Showing {{count}} result for:', // showing 1 result for
        defaultValue_0: 'Showing {{count}} results for:', // showing 0 results for
        defaultValue_plural: 'Showing {{count}} results for:', // showing 2+ results for
        count,
      })}{' '}
      <span id='search-string'>"{searchString}"</span>
      <Clickable id='clear' onClick={resetQueryParams}>
        {t('common.clear', 'clear')}
      </Clickable>
    </SearchResultsContainer>
  );
};

const BreadcrumbContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledSpan = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 175%;
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  margin-bottom: 10px;

  &:hover {
    cursor: pointer;
  }
`;

const BackIcon = styled(ChevronIcon)`
  transform: rotate(90deg);
  margin-right: 5px;
`;

const SearchResultsContainer = styled.div`
  text-transform: initial;
  font-size: 14px;

  #search-string {
    font-weight: bold;
  }
  #clear {
    color: ${({ theme }) => theme.customized.colors.buttonsLinks};
    display: inline;
    text-transform: lowercase;
    margin-left: 5px;
  }
`;

const HeadingContainer = styled.header`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const OuterContainer = styled.div`
  ${(props) =>
    !props.hideBorder &&
    `
  border-bottom: 1px solid ${props.theme.colors.blueGrey[90]};
`}

  padding-bottom: 16px;
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding-bottom: 15px;
  }
`;

const Text = styled.div`
  flex: 1 0 0%;
  min-width: 0;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.primaryBlack};
`;

const Filters = styled.div`
  flex: 0 0 auto;
  min-width: 0;
  padding-left: 12px;
`;

const PillContainer = styled.div`
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    width: 100vw;
    .subcategories-pills {
      margin: 11px 0 40px;
      div {
        &:nth-child(1) {
          padding-left: 0px;
        }
      }
    }
  }
`;

const OfferCardContainer = styled.div`
  width: 100%;
  ${({ theme }) => theme.breakpoints.down(`md`)} {
    margin-bottom: 24px;
    margin-top: 6px;
  }
`;
