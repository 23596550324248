import { Category } from 'src/generics/data/generic-category/data.types';
import { ProductRecommendationSource } from 'src/utils/analytics/trackers/internal-gtm-tracker/internal-gtm-tracker.types';
import {
  useGetSortedProductRecommendations,
  UseGetSortedProductRecommendationsReturn,
} from './use-get-sorted-product-recommendations';
import { useAllowSortByRelevance } from './use-allow-sort-by-relevance';
import { useSetUpRelevanceAsInitialSort } from './use-set-up-relevance-as-initial-sort';

type UseGetSortedProductRecommendationsForCategoryPageParams = {
  category: Category | null;
};

type UseGetSortedProductRecommendationsForCategoryPageReturn = Omit<
  UseGetSortedProductRecommendationsReturn,
  'adapter'
> & {
  recommendationSource: ProductRecommendationSource;
};

export function useGetSortedProductRecommendationsForCategoryPage({
  category,
}: UseGetSortedProductRecommendationsForCategoryPageParams): UseGetSortedProductRecommendationsForCategoryPageReturn {
  const allowSortByRelevance = useAllowSortByRelevance();
  useSetUpRelevanceAsInitialSort({ allowSortByRelevance, category });

  const { productIds, adapter, loading } = useGetSortedProductRecommendations({ category, allowSortByRelevance });

  let recommendationSource = ProductRecommendationSource.native;

  if (adapter in ProductRecommendationSource) {
    recommendationSource = ProductRecommendationSource[adapter];
  }

  return {
    productIds,
    loading,
    recommendationSource,
  };
}
