import { useMemo } from 'react';
import { useGetSortedProductRecommendationsQuery } from 'types/graphql';

import { Category } from 'src/generics/data/generic-category/data.types';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useProductQueryParams from 'src/hooks/use-product-query-params';
import useCart from 'src/hooks/use-cart';
import useUI from 'src/hooks/use-ui';

const BASE_PAGE_LIMIT = 50;
const KIOSK_PAGE_LIMIT = 20;
const NUMBER_OF_PAGES = 2;

type UseGetSortedProductRecommendationsParams = {
  category?: Category | null;
  allowSortByRelevance: boolean;
};

export type UseGetSortedProductRecommendationsReturn = {
  productIds: string[];
  adapter: string;
  loading: boolean;
};

export function useGetSortedProductRecommendations({
  category,
  allowSortByRelevance,
}: UseGetSortedProductRecommendationsParams): UseGetSortedProductRecommendationsReturn {
  const { dispensary } = useDispensary();
  const { menuType } = useCart();
  const { isKiosk, variant: menuVariant } = useUI();
  const { queryParams } = useProductQueryParams();
  const isRelevanceSort = queryParams.sortby === 'relevance';

  const dispensaryId = dispensary?.id;
  const limit = (isKiosk ? KIOSK_PAGE_LIMIT : BASE_PAGE_LIMIT) * NUMBER_OF_PAGES;

  const skip = !dispensaryId || !category || !allowSortByRelevance || !isRelevanceSort;

  const { data, loading } = useGetSortedProductRecommendationsQuery({
    skip,
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        dispensaryId,
        limit,
        filter: {
          menuType,
          menuVariant,
          category,
        },
      },
    },
  });

  const productIds = useMemo(() => data?.getSortedProductRecommendations?.products.map((product) => product.id) ?? [], [
    data?.getSortedProductRecommendations?.products,
  ]);

  const adapter = useMemo(() => data?.getSortedProductRecommendations?.adapter ?? '', [
    data?.getSortedProductRecommendations?.adapter,
  ]);

  return {
    productIds,
    adapter,
    loading,
  };
}
