import { useFlags } from 'launchdarkly-react-client-sdk';
import { useObserver } from 'mobx-react-lite';
import useUI from 'src/hooks/use-ui';
import useProductQueryParams from 'src/hooks/use-product-query-params';
import { useDispensaryCategory } from 'src/hooks/use-dispensary-category';

import useUser from 'src/hooks/use-user';

export function useAllowSortByRelevance(): boolean {
  const flags = useFlags();
  const user = useUser();
  const isUserLoggedIn = useObserver(() => user.isLoggedIn);
  const userIsLoading = useObserver(() => user.loading);
  const { queryParams } = useProductQueryParams();
  const category = useDispensaryCategory();
  const { isKiosk } = useUI();

  const sortByRelevanceEnabled = flags['growth.ads.personalization.sort-by-relevance.rollout'] ?? false;
  const sortByRelevanceLoginScope =
    flags['growth.ads.personalization.sort-by-relevance-customer-log-in-scope.rollout'] ?? 'logged-in-only';

  const hasSearch = queryParams.search?.length > 0;

  if (!sortByRelevanceEnabled || hasSearch || !category) {
    return false;
  }

  if (sortByRelevanceLoginScope === 'all') {
    return true;
  }

  return isUserLoggedIn && !userIsLoading && !isKiosk;
}
