import React, { useMemo } from 'react';
import _ from 'lodash';
import styled, { css } from 'styled-components';
import { bindHover, bindPopover, bindTrigger, PopupState as PopupStateType } from 'material-ui-popup-state/hooks';
import PopupState from 'material-ui-popup-state';
import { mediaQueries } from 'shared/styles';

import { BlackSparkleIcon } from 'src/assets/black-sparkle-icon';
import SvgChevronIcon from 'src/assets/chevron-icon';
import useProductQueryParams from 'src/hooks/use-product-query-params';
import useMenuSortOptions from 'src/hooks/use-menu-sort-options';
import { Typography } from 'src/typography';
import { buttonResetStyles, PopoverNavButton, PopoverNavItem, StyledHoverPopover } from './menu-sort-v2.styles';

type MenuSortOption = {
  key: string;
  label: string;
  value?: string;
};

const formatMenuSortOptions = (menuSortOptions): Array<MenuSortOption> =>
  menuSortOptions.map(({ key, label }) => ({
    key,
    label: _.capitalize(label.replace(/:/g, '')),
  }));

export function MenuSortV2(): JSX.Element {
  const { queryParams, setQueryParams } = useProductQueryParams();
  const menuSortOptions = useMenuSortOptions();

  const formattedSortOptions = useMemo(() => formatMenuSortOptions(menuSortOptions), [menuSortOptions]);

  const selectedSortLabel = useMemo(() => formattedSortOptions.find(({ key }) => key === queryParams.sortby)?.label, [
    formattedSortOptions,
    queryParams.sortby,
  ]);

  const handleClick = (key: string, popupState: PopupStateType): void => {
    setQueryParams({ sortby: key });
    void popupState.close();
  };

  const relevanceSelected = queryParams.sortby === 'relevance' && selectedSortLabel === 'Relevance';
  const sortLabel = selectedSortLabel ? 'Sort: ' : 'Sort by: ';

  return (
    <PopupState variant='popover' popupId='menuSortPopover'>
      {(popupState) => (
        <>
          <SortTypeButton
            {...bindHover(popupState)}
            {...bindTrigger(popupState)}
            data-testid='menu-sort-v2-popover-toggle'
          >
            <SortLabel size='small'>{sortLabel}</SortLabel>
            {relevanceSelected && <BlackSparkleIcon />}
            {selectedSortLabel && (
              <SortLabel size='small' data-testid='menu-sort-v2-select-label'>
                {selectedSortLabel}
              </SortLabel>
            )}
            <SvgChevronIcon height='7px' width='12px' />
          </SortTypeButton>

          <StyledHoverPopover
            {...bindPopover(popupState)}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            data-testid='menu-sort-v2-popover'
            $offsetTop
          >
            <nav>
              <SortByLabelItem $isActive>Sort by</SortByLabelItem>

              {formattedSortOptions.map(({ key, label }) => (
                <PopoverNavButton
                  key={key}
                  onClick={() => handleClick(key, popupState)}
                  $isActive={queryParams.sortby === key}
                  data-testid={`menu-sort-v2-select-${key}`}
                >
                  {label}
                </PopoverNavButton>
              ))}
            </nav>
          </StyledHoverPopover>
        </>
      )}
    </PopupState>
  );
}

const sortSelectionStyle = css`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  svg {
    color: #828a8f;
  }
`;

const SortTypeButton = styled.button`
  ${sortSelectionStyle}
  ${buttonResetStyles}
  align-items: center;
  display: flex;
  position: relative;
  gap: 8px;
  color: ${({ theme }) => theme.colors.primaryBlack};

  @media ${mediaQueries.largePhone} {
    gap: 4px;
  }

  &::after {
    content: '';
    height: 8px;
    left: 0;
    position: absolute;
    top: 100%;
    width: 100%;
  }
`;

const SortByLabelItem = styled(PopoverNavItem)`
  color: #828a8f;
`;

const SortLabel = styled(Typography.Label)`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
`;
