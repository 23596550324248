import React from 'react';
import styled from 'styled-components';
import { useColors } from 'contexts/colors';
import { Clickable } from 'shared/components';

import ChevronIcon from 'assets/chevron-icon';
import { Typography } from 'src/typography';

const StandardContainer = styled.div`
  place-items: center;
  display: flex;
`;

const ClickableContainer = styled(Clickable)`
  place-items: center;
  display: flex;
  cursor: pointer;
`;

const Heading = styled(Typography.Heading)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.primaryBlack};
  margin: 0 9px 0 0;
`;

const IconContainer = styled.span`
  display: inline-block;
  transform: ${(props) => (props.isOpen ? `rotate(0deg)` : `rotate(-90deg)`)};
`;

export default function Header(props) {
  const { collapsible, heading, isOpen, onOpen } = props;
  const colors = useColors();

  const Container = collapsible ? ClickableContainer : StandardContainer;

  return (
    <Container aria-expanded={collapsible ? isOpen : null} collapsible={collapsible} onClick={onOpen}>
      <Heading size='xxsmall' tag='h3' casing='uppercase'>
        {heading}
      </Heading>
      {collapsible && (
        <IconContainer isOpen={isOpen}>
          <ChevronIcon height='7px' width='12px' fill={colors.grey[45]} />
        </IconContainer>
      )}
    </Container>
  );
}
