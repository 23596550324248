import { useState, useEffect } from 'react';
import { Category } from 'src/generics/data/generic-category/data.types';
import useProductQueryParams from 'src/hooks/use-product-query-params';

type UseSetUpRelevanceAsInitialSortParams = {
  allowSortByRelevance: boolean;
  category: Category | null;
};

export function useSetUpRelevanceAsInitialSort({
  allowSortByRelevance,
  category,
}: UseSetUpRelevanceAsInitialSortParams): void {
  const [didSeeRelevanceSort, setDidSeeRelevanceSort] = useState(false);
  const { queryParams, setQueryParams } = useProductQueryParams();

  const isRelevanceSort = queryParams.sortby === 'relevance';
  const isDefaultSort = queryParams.sortby === 'default';

  useEffect(() => {
    setDidSeeRelevanceSort(false);
  }, [category]);

  useEffect(() => {
    if (allowSortByRelevance && !didSeeRelevanceSort) {
      setQueryParams({ sortby: 'relevance' });
      setDidSeeRelevanceSort(true);
    }
  }, [allowSortByRelevance, didSeeRelevanceSort, isDefaultSort, setQueryParams]);

  useEffect(() => {
    if (!allowSortByRelevance && isRelevanceSort) {
      setQueryParams({ sortby: 'default' });
    }
  }, [allowSortByRelevance, isRelevanceSort, setQueryParams]);
}
