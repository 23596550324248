import React from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import _ from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useMenuFilterOptions from 'hooks/use-menu-filter-options';
import useProductQueryParams from 'hooks/use-product-query-params';
import useTranslation from 'hooks/use-translation';
import { useDispensaryCategory } from 'src/hooks/use-dispensary-category';

import SubcategoryPill from './subcategory-pill';

export default function SubcategoriesPills({ isSpecials = false, specialId, specialType, ...otherProps }) {
  const category = useDispensaryCategory();
  const { subcategories } = useMenuFilterOptions({ specials: isSpecials, specialId, specialType });
  const { query } = useRouter();
  const { queryParams } = useProductQueryParams();
  const selectedSubcategories = query.subcategory ?? queryParams.subcategories[0];
  return (
    <Pills
      isSpecials={isSpecials}
      category={category}
      subcategories={subcategories}
      selectedSubcategories={selectedSubcategories}
      {...otherProps}
    />
  );
}

export const Pills = ({ isSpecials, category, subcategories, selectedSubcategories, ...otherProps }) => {
  const { t } = useTranslation();
  const flags = useFlags();
  const updatedFilterIconsEnabled = flags['growth.ecomm.filter-icon-update.rollout'] ?? false;

  const allLabel = t('sidebar-filters.subcategories-header', {
    defaultValue: updatedFilterIconsEnabled ? 'All' : 'All {{category}}',
    category: !category || category?.key === `all` ? `Subcategories` : category.label,
  });

  if (_.isEmpty(subcategories)) {
    return null;
  }

  return (
    <Container className='subcategories-pills' {...otherProps}>
      <PillsContainer>
        <SubcategoryPill
          key='all'
          isSpecials={isSpecials}
          label={allLabel}
          selected={_.isEmpty(selectedSubcategories)}
          value={[]}
        />
        {_.map(subcategories, (subcategory) => (
          <SubcategoryPill
            key={subcategory.key}
            isSpecials={isSpecials}
            isSubcategory
            label={subcategory.label}
            selected={selectedSubcategories === subcategory.key}
            value={subcategory.value}
          />
        ))}
      </PillsContainer>
    </Container>
  );
};

const PillsContainer = styled.div`
  display: flex;
  overflow: scroll;
  padding-left: 25px;

  > :last-child {
    padding-right: 25px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Container = styled.div`
  display: flex;
  margin-top: 40px;
  width: 100%;
`;
