import _truncate from "lodash/truncate";
var __jsx = React.createElement;
import React, { useState } from 'react';
import styled from 'styled-components';
import { mediaQueries } from 'shared/styles';
import { DEFAULT_SPECIALS_CARD_IMG } from 'shared/constants';
import { replaceAWSSourceWithImgix } from 'shared/helpers/imgix';
import { useMediaQuery } from '@material-ui/core';
var LONG_DESCRIPTION_MIN_LENGTH_MOBILE = 85;
var LONG_DESCRIPTION_MIN_LENGTH = 213;
export default function IndividualBogoCard(_ref) {
  var displayImage = _ref.displayImage,
      displayName = _ref.displayName,
      displayDescription = _ref.displayDescription,
      _ref$showExpiredSpeci = _ref.showExpiredSpecial,
      showExpiredSpecial = _ref$showExpiredSpeci === void 0 ? false : _ref$showExpiredSpeci;

  var _useState = useState(false),
      showAllText = _useState[0],
      setShowAllText = _useState[1];

  var isMobile = useMediaQuery(mediaQueries.phone);
  var longDescriptionMinLength = isMobile ? LONG_DESCRIPTION_MIN_LENGTH_MOBILE : LONG_DESCRIPTION_MIN_LENGTH;
  var isLongDescription = (displayDescription === null || displayDescription === void 0 ? void 0 : displayDescription.length) >= longDescriptionMinLength;
  var defaultDescription = !isLongDescription ? displayDescription : _truncate(displayDescription, {
    length: longDescriptionMinLength
  });

  function showHideDescription() {
    setShowAllText(function (prevShowAllText) {
      return !prevShowAllText;
    });
  }

  return __jsx(IndividualBogoCardContainer, {
    showExpiredSpecial: showExpiredSpecial
  }, __jsx(BackgroundImageContainer, {
    imageUrl: replaceAWSSourceWithImgix(displayImage) || DEFAULT_SPECIALS_CARD_IMG
  }), __jsx(IndividualBogoTextContainer, null, __jsx(IndividualBogoTitle, null, displayName), defaultDescription && __jsx(IndividualBogoDescription, null, showAllText ? displayDescription : defaultDescription, "\xA0", isLongDescription && __jsx(DescriptionExpanderLink, {
    onClick: showHideDescription
  }, showAllText ? 'Show less' : 'Show more'))));
}
var BackgroundImageContainer = styled.div.withConfig({
  displayName: "individual-bogo-card__BackgroundImageContainer",
  componentId: "vybd7e-0"
})(["background-image:", ";background-position:center;background-size:cover;border-radius:20px 0 0 20px;min-height:148px;position:absolute;top:0;left:0;bottom:0;right:43%;@media ", "{right:0;border-radius:20px;background-image:", ";}"], function (_ref2) {
  var imageUrl = _ref2.imageUrl;
  return "linear-gradient(90deg, rgba(11, 31, 50, 0.38) 0%, #0B1F32 100%), url(".concat(imageUrl, ")");
}, mediaQueries.phone, function (_ref3) {
  var imageUrl = _ref3.imageUrl;
  return "linear-gradient(180deg, rgba(11, 31, 50, 0.11) 0%, #0B1F32 66.59%), url(".concat(imageUrl, ")");
});
var DescriptionExpanderLink = styled.span.withConfig({
  displayName: "individual-bogo-card__DescriptionExpanderLink",
  componentId: "vybd7e-1"
})(["cursor:pointer;display:inline;font-weight:bold;font-size:14px;line-height:175%;color:#0b99e6;white-space:nowrap;"]);
var IndividualBogoTextContainer = styled.div.withConfig({
  displayName: "individual-bogo-card__IndividualBogoTextContainer",
  componentId: "vybd7e-2"
})(["z-index:200;"]);
var IndividualBogoDescription = styled.p.withConfig({
  displayName: "individual-bogo-card__IndividualBogoDescription",
  componentId: "vybd7e-3"
})(["display:inline;font-size:13px;line-height:165%;color:#fff;word-break:break-word;"]);
var IndividualBogoTitle = styled.h3.withConfig({
  displayName: "individual-bogo-card__IndividualBogoTitle",
  componentId: "vybd7e-4"
})(["font-weight:bold;font-size:20px;line-height:24px;color:#fff;margin-bottom:11px;word-break:break-word;"]);
var IndividualBogoCardContainer = styled.div.withConfig({
  displayName: "individual-bogo-card__IndividualBogoCardContainer",
  componentId: "vybd7e-5"
})(["width:100%;min-height:148px;border-radius:20px;display:flex;padding:47px 55px;margin-bottom:55px;", " background-color:#0b1f32;position:relative;@media ", "{margin-bottom:20px;min-width:325px;min-height:179px;padding:70px 25px 25px;flex-direction:column;justify-content:center;}"], function (props) {
  return props.showExpiredSpecial && "\n    filter: grayscale(80%) opacity(0.75);\n  ";
}, mediaQueries.phone);