import _ from 'lodash';
import { useRouter } from 'next/router';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { MenuSortOptions, CategoriesWithPotencies } from 'shared/constants';
import useDispensaryFlag from 'shared/hooks/use-dispensary-flag';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import { useAllowSortByRelevance } from 'src/personalization/utils/hooks';
import useMenuFilterOptions from './use-menu-filter-options';
import { useDispensaryCategory } from './use-dispensary-category';

export default function useMenuSortOptions() {
  const category = useDispensaryCategory();
  const router = useRouter();
  const { brands } = useMenuFilterOptions();
  const { dispensary } = useDispensary();
  const allowSortByRelevance = useAllowSortByRelevance();
  const flags = useFlags();
  const isRecentlyAddedFlagEnabled = useDispensaryFlag(`growth.ecomm.recently-added-sort.rollout`, dispensary?.id);
  const featuredMenuSortOptionEnabled = flags['growth.ecomm.default-as-featured-menu-sort-option.rollout'] ?? false;

  const onBrandsPage = _.includes(router.route, 'brands');
  const onSpecialsPage = _.includes(router.route, 'specials');
  const onSearchResultsPage = category?.key === 'all';
  const hideRelevance = onBrandsPage || onSpecialsPage || onSearchResultsPage || !category?.key;
  const dontShowPotencies =
    !_.includes(CategoriesWithPotencies, category?.key) && !onBrandsPage && !onSpecialsPage && !onSearchResultsPage;
  const dontShowBrands = onBrandsPage || _.isEmpty(brands);

  let menuSortOptions = [...MenuSortOptions];

  if (dontShowBrands) {
    menuSortOptions = _.filter(menuSortOptions, ({ key }) => key !== 'brand');
  }

  if (dontShowPotencies) {
    menuSortOptions = _.filter(menuSortOptions, ({ key }) => key !== 'potencyhightolow' && key !== 'potencylowtohigh');
  }

  // If the flag is not turned on, remove Recently Added from the sort options
  if (!isRecentlyAddedFlagEnabled) {
    menuSortOptions = _.filter(menuSortOptions, ({ key }) => key !== 'recentlyadded');
  }

  if (hideRelevance || !allowSortByRelevance) {
    menuSortOptions = _.filter(menuSortOptions, ({ key }) => key !== 'relevance');
  }

  if (!featuredMenuSortOptionEnabled) {
    menuSortOptions = _.filter(menuSortOptions, ({ key }) => key !== 'default');
  }

  return menuSortOptions;
}
