import React from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';

import ContentWrapper from 'components/core/content-wrapper';

export function OneColumn({ children, ...props }) {
  const { showingBreadCrumbs } = props;

  return (
    <ContentWrapper {...props}>
      <Container accommodateForBreadCrumbs={!!showingBreadCrumbs}>{children}</Container>
    </ContentWrapper>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 50px 0 126px 0;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: ${({ accommodateForBreadCrumbs }) => (accommodateForBreadCrumbs ? '0' : `25px 0`)};
  }
  ${space}
`;

export const OneColumnContent = styled.div`
  flex: 1 0 0%;
  min-width: 0;
`;
